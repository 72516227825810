/* eslint-disable */
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "google.travel.sustainability.travelimpactmodel.v1";

/** Creating fake google.type.Date */
export interface DateMessage {
  year: number;
  month: number;
  day: number;
}

export interface ComputeFlightEmissionsRequest {
  /** Direct flights to return emission estimates for. */
  flights: Flight[];
  /**
   * If true, the response will contain the details about the inputs used to
   * calculate the emissions data.
   */
  includeEmissionsInputs: boolean;
  /**
   * If true, the response will contain the details about the breakdown of the
   * emissions data.
   */
  includeEmissionsBreakdown: boolean;
}

/**
 * All details related to a single request item for a direct flight emission
 * estimates.
 */
export interface Flight {
  /** IATA airport code for flight origin, e.g. "LHR". */
  origin: string;
  /** IATA airport code for flight destination, e.g. "JFK". */
  destination: string;
  /** IATA carrier code, e.g. "AA". */
  operatingCarrierCode: string;
  /** Flight number, e.g. 324. */
  flightNumber: string;
  /**
   * Date of the flight in the time zone of the origin airport. Must be a date
   * in the present or future.
   */
  departureDate: DateMessage | undefined;
}

export interface ComputeFlightEmissionsResponse {
  /** List of flight legs with emission estimates. */
  flightEmissions: FlightWithEmissions[];
  /**
   * The model version under which emission estimates for all flights in this
   * response were computed.
   */
  modelVersion: ModelVersion | undefined;
}

/** Direct flight with emission estimates. */
export interface FlightWithEmissions {
  /**
   * Matches the flight identifiers in the request.
   * Note: all IATA codes are capitalized.
   */
  flight:
    | Flight
    | undefined;
  /**
   * Per-passenger emission estimate numbers. Will not be present if emissions
   * could not be computed.
   */
  emissionsGramsPerPax?:
    | EmissionsGramsPerPax
    | undefined;
  /**
   * Details about the inputs used to calculate the emissions data, including
   * the contributing factors with their data sources.
   * Populated when `include_emissions_inputs` in the request is set.
   */
  emissionsInputs?:
    | EmissionsInputs
    | undefined;
  /**
   * Details about the various emissions portions of the total
   * emissions_grams_per_pax value. The value of the summed breakdowns should
   * always equal emissions_grams_per_pax. Populated when
   * 'include_emissions_breakdown' in the request is set to true and applies to
   * all flights returned.
   */
  emissionsBreakdown?: EmissionsBreakdown | undefined;
}

/**
 * Travel Impact Model version. For more information about the model
 * versioning see
 * https://github.com/google/travel-impact-model/#versioning.
 */
export interface ModelVersion {
  /**
   * Major versions: Major changes to methodology (e.g. adding new data sources
   * to the model that lead to major output changes). Such changes will be
   * infrequent and announced well in advance.
   */
  major?:
    | number
    | undefined;
  /**
   * Minor versions: Changes to the model that, while being consistent across
   * schema versions, change the model parameters or implementation.
   */
  minor?:
    | number
    | undefined;
  /**
   * Patch versions: Implementation changes meant to address bugs or
   * inaccuracies in the model implementation.
   */
  patch?:
    | number
    | undefined;
  /**
   * Dated versions: Model datasets are recreated with refreshed input data
   * but no change to the algorithms regularly.
   */
  dated?: string | undefined;
}

/** Grouped emissions per seating class results. */
export interface EmissionsGramsPerPax {
  /**
   * Emissions for one passenger in first class in grams.
   * This field is always computed and populated, regardless of whether the
   * aircraft has first class seats or not.
   */
  first?:
    | number
    | undefined;
  /**
   * Emissions for one passenger in business class in grams.
   * This field is always computed and populated, regardless of whether the
   * aircraft has business class seats or not.
   */
  business?:
    | number
    | undefined;
  /**
   * Emissions for one passenger in premium economy class in grams.
   * This field is always computed and populated, regardless of whether the
   * aircraft has premium economy class seats or not.
   */
  premiumEconomy?:
    | number
    | undefined;
  /**
   * Emissions for one passenger in economy class in grams.
   * This field is always computed and populated, regardless of whether the
   * aircraft has economy class seats or not.
   */
  economy?: number | undefined;
}

/**
 * Details about the inputs used to calculate the emissions data, specifically
 * the contributing factors along with their data sources.
 */
export interface EmissionsInputs {
  /**
   * All contributing factors to compute the flight emissions, mapped by the
   * alphanumeric name of the contributing factor.
   */
  emissionsInputEntries: { [key: string]: EmissionsInputs_EmissionsInputEntry };
}

/** Details about a single contributing factor to compute the flight emissions. */
export interface EmissionsInputs_EmissionsInputEntry {
  /** The source this data came from. */
  dataSource?:
    | string
    | undefined;
  /** The data strategy used to compute the value. */
  dataStrategy?: string | undefined;
}

export interface EmissionsInputs_EmissionsInputEntriesEntry {
  key: string;
  value: EmissionsInputs_EmissionsInputEntry | undefined;
}

/**
 * Details about the various emissions portions of the total
 * emissions_grams_per_pax value. The value of the summed breakdowns should
 * always equal emissions_grams_per_pax.
 */
export interface EmissionsBreakdown {
  /**
   * Per-passenger well-to-tank emission estimate numbers. Will not be present
   * if emissions could not be computed. For the list of reasons why emissions
   * could not be computed, see
   * [ComputeFlightEmissions][google.travel.sustainability.travelimpactmodel.v1.ComputeFlightEmissions].
   */
  wttEmissionsGramsPerPax:
    | EmissionsGramsPerPax
    | undefined;
  /**
   * Per-passenger tank-to-wake emission estimate numbers. Will not be present
   * if emissions could not be computed. For the list of reasons why emissions
   * could not be computed, see
   * [ComputeFlightEmissions][google.travel.sustainability.travelimpactmodel.v1.ComputeFlightEmissions].
   */
  ttwEmissionsGramsPerPax: EmissionsGramsPerPax | undefined;
}

function createBaseDateMessage(): DateMessage {
  return { year: 0, month: 0, day: 0 };
}

export const DateMessage = {
  encode(message: DateMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.year !== 0) {
      writer.uint32(8).int32(message.year);
    }
    if (message.month !== 0) {
      writer.uint32(16).int32(message.month);
    }
    if (message.day !== 0) {
      writer.uint32(24).int32(message.day);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DateMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDateMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.year = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.month = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.day = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DateMessage {
    return {
      year: isSet(object.year) ? globalThis.Number(object.year) : 0,
      month: isSet(object.month) ? globalThis.Number(object.month) : 0,
      day: isSet(object.day) ? globalThis.Number(object.day) : 0,
    };
  },

  toJSON(message: DateMessage): unknown {
    const obj: any = {};
    if (message.year !== 0) {
      obj.year = Math.round(message.year);
    }
    if (message.month !== 0) {
      obj.month = Math.round(message.month);
    }
    if (message.day !== 0) {
      obj.day = Math.round(message.day);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DateMessage>, I>>(base?: I): DateMessage {
    return DateMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DateMessage>, I>>(object: I): DateMessage {
    const message = createBaseDateMessage();
    message.year = object.year ?? 0;
    message.month = object.month ?? 0;
    message.day = object.day ?? 0;
    return message;
  },
};

function createBaseComputeFlightEmissionsRequest(): ComputeFlightEmissionsRequest {
  return { flights: [], includeEmissionsInputs: false, includeEmissionsBreakdown: false };
}

export const ComputeFlightEmissionsRequest = {
  encode(message: ComputeFlightEmissionsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.flights) {
      Flight.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.includeEmissionsInputs === true) {
      writer.uint32(16).bool(message.includeEmissionsInputs);
    }
    if (message.includeEmissionsBreakdown === true) {
      writer.uint32(24).bool(message.includeEmissionsBreakdown);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ComputeFlightEmissionsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseComputeFlightEmissionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.flights.push(Flight.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.includeEmissionsInputs = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.includeEmissionsBreakdown = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ComputeFlightEmissionsRequest {
    return {
      flights: globalThis.Array.isArray(object?.flights) ? object.flights.map((e: any) => Flight.fromJSON(e)) : [],
      includeEmissionsInputs: isSet(object.includeEmissionsInputs)
        ? globalThis.Boolean(object.includeEmissionsInputs)
        : false,
      includeEmissionsBreakdown: isSet(object.includeEmissionsBreakdown)
        ? globalThis.Boolean(object.includeEmissionsBreakdown)
        : false,
    };
  },

  toJSON(message: ComputeFlightEmissionsRequest): unknown {
    const obj: any = {};
    if (message.flights?.length) {
      obj.flights = message.flights.map((e) => Flight.toJSON(e));
    }
    if (message.includeEmissionsInputs === true) {
      obj.includeEmissionsInputs = message.includeEmissionsInputs;
    }
    if (message.includeEmissionsBreakdown === true) {
      obj.includeEmissionsBreakdown = message.includeEmissionsBreakdown;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ComputeFlightEmissionsRequest>, I>>(base?: I): ComputeFlightEmissionsRequest {
    return ComputeFlightEmissionsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ComputeFlightEmissionsRequest>, I>>(
    object: I,
  ): ComputeFlightEmissionsRequest {
    const message = createBaseComputeFlightEmissionsRequest();
    message.flights = object.flights?.map((e) => Flight.fromPartial(e)) || [];
    message.includeEmissionsInputs = object.includeEmissionsInputs ?? false;
    message.includeEmissionsBreakdown = object.includeEmissionsBreakdown ?? false;
    return message;
  },
};

function createBaseFlight(): Flight {
  return { origin: "", destination: "", operatingCarrierCode: "", flightNumber: "", departureDate: undefined };
}

export const Flight = {
  encode(message: Flight, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.origin !== "") {
      writer.uint32(10).string(message.origin);
    }
    if (message.destination !== "") {
      writer.uint32(18).string(message.destination);
    }
    if (message.operatingCarrierCode !== "") {
      writer.uint32(26).string(message.operatingCarrierCode);
    }
    if (message.flightNumber !== "") {
      writer.uint32(34).string(message.flightNumber);
    }
    if (message.departureDate !== undefined) {
      DateMessage.encode(message.departureDate, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Flight {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFlight();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.origin = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.destination = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.operatingCarrierCode = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.flightNumber = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.departureDate = DateMessage.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Flight {
    return {
      origin: isSet(object.origin) ? globalThis.String(object.origin) : "",
      destination: isSet(object.destination) ? globalThis.String(object.destination) : "",
      operatingCarrierCode: isSet(object.operatingCarrierCode) ? globalThis.String(object.operatingCarrierCode) : "",
      flightNumber: isSet(object.flightNumber) ? globalThis.String(object.flightNumber) : "",
      departureDate: isSet(object.departureDate) ? DateMessage.fromJSON(object.departureDate) : undefined,
    };
  },

  toJSON(message: Flight): unknown {
    const obj: any = {};
    if (message.origin !== "") {
      obj.origin = message.origin;
    }
    if (message.destination !== "") {
      obj.destination = message.destination;
    }
    if (message.operatingCarrierCode !== "") {
      obj.operatingCarrierCode = message.operatingCarrierCode;
    }
    if (message.flightNumber !== "") {
      obj.flightNumber = message.flightNumber;
    }
    if (message.departureDate !== undefined) {
      obj.departureDate = DateMessage.toJSON(message.departureDate);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Flight>, I>>(base?: I): Flight {
    return Flight.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Flight>, I>>(object: I): Flight {
    const message = createBaseFlight();
    message.origin = object.origin ?? "";
    message.destination = object.destination ?? "";
    message.operatingCarrierCode = object.operatingCarrierCode ?? "";
    message.flightNumber = object.flightNumber ?? "";
    message.departureDate = (object.departureDate !== undefined && object.departureDate !== null)
      ? DateMessage.fromPartial(object.departureDate)
      : undefined;
    return message;
  },
};

function createBaseComputeFlightEmissionsResponse(): ComputeFlightEmissionsResponse {
  return { flightEmissions: [], modelVersion: undefined };
}

export const ComputeFlightEmissionsResponse = {
  encode(message: ComputeFlightEmissionsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.flightEmissions) {
      FlightWithEmissions.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.modelVersion !== undefined) {
      ModelVersion.encode(message.modelVersion, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ComputeFlightEmissionsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseComputeFlightEmissionsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.flightEmissions.push(FlightWithEmissions.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.modelVersion = ModelVersion.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ComputeFlightEmissionsResponse {
    return {
      flightEmissions: globalThis.Array.isArray(object?.flightEmissions)
        ? object.flightEmissions.map((e: any) => FlightWithEmissions.fromJSON(e))
        : [],
      modelVersion: isSet(object.modelVersion) ? ModelVersion.fromJSON(object.modelVersion) : undefined,
    };
  },

  toJSON(message: ComputeFlightEmissionsResponse): unknown {
    const obj: any = {};
    if (message.flightEmissions?.length) {
      obj.flightEmissions = message.flightEmissions.map((e) => FlightWithEmissions.toJSON(e));
    }
    if (message.modelVersion !== undefined) {
      obj.modelVersion = ModelVersion.toJSON(message.modelVersion);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ComputeFlightEmissionsResponse>, I>>(base?: I): ComputeFlightEmissionsResponse {
    return ComputeFlightEmissionsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ComputeFlightEmissionsResponse>, I>>(
    object: I,
  ): ComputeFlightEmissionsResponse {
    const message = createBaseComputeFlightEmissionsResponse();
    message.flightEmissions = object.flightEmissions?.map((e) => FlightWithEmissions.fromPartial(e)) || [];
    message.modelVersion = (object.modelVersion !== undefined && object.modelVersion !== null)
      ? ModelVersion.fromPartial(object.modelVersion)
      : undefined;
    return message;
  },
};

function createBaseFlightWithEmissions(): FlightWithEmissions {
  return {
    flight: undefined,
    emissionsGramsPerPax: undefined,
    emissionsInputs: undefined,
    emissionsBreakdown: undefined,
  };
}

export const FlightWithEmissions = {
  encode(message: FlightWithEmissions, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.flight !== undefined) {
      Flight.encode(message.flight, writer.uint32(10).fork()).ldelim();
    }
    if (message.emissionsGramsPerPax !== undefined) {
      EmissionsGramsPerPax.encode(message.emissionsGramsPerPax, writer.uint32(18).fork()).ldelim();
    }
    if (message.emissionsInputs !== undefined) {
      EmissionsInputs.encode(message.emissionsInputs, writer.uint32(34).fork()).ldelim();
    }
    if (message.emissionsBreakdown !== undefined) {
      EmissionsBreakdown.encode(message.emissionsBreakdown, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FlightWithEmissions {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFlightWithEmissions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.flight = Flight.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.emissionsGramsPerPax = EmissionsGramsPerPax.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.emissionsInputs = EmissionsInputs.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.emissionsBreakdown = EmissionsBreakdown.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FlightWithEmissions {
    return {
      flight: isSet(object.flight) ? Flight.fromJSON(object.flight) : undefined,
      emissionsGramsPerPax: isSet(object.emissionsGramsPerPax)
        ? EmissionsGramsPerPax.fromJSON(object.emissionsGramsPerPax)
        : undefined,
      emissionsInputs: isSet(object.emissionsInputs) ? EmissionsInputs.fromJSON(object.emissionsInputs) : undefined,
      emissionsBreakdown: isSet(object.emissionsBreakdown)
        ? EmissionsBreakdown.fromJSON(object.emissionsBreakdown)
        : undefined,
    };
  },

  toJSON(message: FlightWithEmissions): unknown {
    const obj: any = {};
    if (message.flight !== undefined) {
      obj.flight = Flight.toJSON(message.flight);
    }
    if (message.emissionsGramsPerPax !== undefined) {
      obj.emissionsGramsPerPax = EmissionsGramsPerPax.toJSON(message.emissionsGramsPerPax);
    }
    if (message.emissionsInputs !== undefined) {
      obj.emissionsInputs = EmissionsInputs.toJSON(message.emissionsInputs);
    }
    if (message.emissionsBreakdown !== undefined) {
      obj.emissionsBreakdown = EmissionsBreakdown.toJSON(message.emissionsBreakdown);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FlightWithEmissions>, I>>(base?: I): FlightWithEmissions {
    return FlightWithEmissions.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FlightWithEmissions>, I>>(object: I): FlightWithEmissions {
    const message = createBaseFlightWithEmissions();
    message.flight = (object.flight !== undefined && object.flight !== null)
      ? Flight.fromPartial(object.flight)
      : undefined;
    message.emissionsGramsPerPax = (object.emissionsGramsPerPax !== undefined && object.emissionsGramsPerPax !== null)
      ? EmissionsGramsPerPax.fromPartial(object.emissionsGramsPerPax)
      : undefined;
    message.emissionsInputs = (object.emissionsInputs !== undefined && object.emissionsInputs !== null)
      ? EmissionsInputs.fromPartial(object.emissionsInputs)
      : undefined;
    message.emissionsBreakdown = (object.emissionsBreakdown !== undefined && object.emissionsBreakdown !== null)
      ? EmissionsBreakdown.fromPartial(object.emissionsBreakdown)
      : undefined;
    return message;
  },
};

function createBaseModelVersion(): ModelVersion {
  return { major: undefined, minor: undefined, patch: undefined, dated: undefined };
}

export const ModelVersion = {
  encode(message: ModelVersion, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.major !== undefined) {
      writer.uint32(8).int32(message.major);
    }
    if (message.minor !== undefined) {
      writer.uint32(16).int32(message.minor);
    }
    if (message.patch !== undefined) {
      writer.uint32(24).int32(message.patch);
    }
    if (message.dated !== undefined) {
      writer.uint32(34).string(message.dated);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModelVersion {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModelVersion();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.major = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.minor = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.patch = reader.int32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.dated = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ModelVersion {
    return {
      major: isSet(object.major) ? globalThis.Number(object.major) : undefined,
      minor: isSet(object.minor) ? globalThis.Number(object.minor) : undefined,
      patch: isSet(object.patch) ? globalThis.Number(object.patch) : undefined,
      dated: isSet(object.dated) ? globalThis.String(object.dated) : undefined,
    };
  },

  toJSON(message: ModelVersion): unknown {
    const obj: any = {};
    if (message.major !== undefined) {
      obj.major = Math.round(message.major);
    }
    if (message.minor !== undefined) {
      obj.minor = Math.round(message.minor);
    }
    if (message.patch !== undefined) {
      obj.patch = Math.round(message.patch);
    }
    if (message.dated !== undefined) {
      obj.dated = message.dated;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ModelVersion>, I>>(base?: I): ModelVersion {
    return ModelVersion.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ModelVersion>, I>>(object: I): ModelVersion {
    const message = createBaseModelVersion();
    message.major = object.major ?? undefined;
    message.minor = object.minor ?? undefined;
    message.patch = object.patch ?? undefined;
    message.dated = object.dated ?? undefined;
    return message;
  },
};

function createBaseEmissionsGramsPerPax(): EmissionsGramsPerPax {
  return { first: undefined, business: undefined, premiumEconomy: undefined, economy: undefined };
}

export const EmissionsGramsPerPax = {
  encode(message: EmissionsGramsPerPax, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.first !== undefined) {
      writer.uint32(8).int32(message.first);
    }
    if (message.business !== undefined) {
      writer.uint32(16).int32(message.business);
    }
    if (message.premiumEconomy !== undefined) {
      writer.uint32(24).int32(message.premiumEconomy);
    }
    if (message.economy !== undefined) {
      writer.uint32(32).int32(message.economy);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EmissionsGramsPerPax {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmissionsGramsPerPax();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.first = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.business = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.premiumEconomy = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.economy = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EmissionsGramsPerPax {
    return {
      first: isSet(object.first) ? globalThis.Number(object.first) : undefined,
      business: isSet(object.business) ? globalThis.Number(object.business) : undefined,
      premiumEconomy: isSet(object.premiumEconomy) ? globalThis.Number(object.premiumEconomy) : undefined,
      economy: isSet(object.economy) ? globalThis.Number(object.economy) : undefined,
    };
  },

  toJSON(message: EmissionsGramsPerPax): unknown {
    const obj: any = {};
    if (message.first !== undefined) {
      obj.first = Math.round(message.first);
    }
    if (message.business !== undefined) {
      obj.business = Math.round(message.business);
    }
    if (message.premiumEconomy !== undefined) {
      obj.premiumEconomy = Math.round(message.premiumEconomy);
    }
    if (message.economy !== undefined) {
      obj.economy = Math.round(message.economy);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EmissionsGramsPerPax>, I>>(base?: I): EmissionsGramsPerPax {
    return EmissionsGramsPerPax.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EmissionsGramsPerPax>, I>>(object: I): EmissionsGramsPerPax {
    const message = createBaseEmissionsGramsPerPax();
    message.first = object.first ?? undefined;
    message.business = object.business ?? undefined;
    message.premiumEconomy = object.premiumEconomy ?? undefined;
    message.economy = object.economy ?? undefined;
    return message;
  },
};

function createBaseEmissionsInputs(): EmissionsInputs {
  return { emissionsInputEntries: {} };
}

export const EmissionsInputs = {
  encode(message: EmissionsInputs, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.emissionsInputEntries).forEach(([key, value]) => {
      EmissionsInputs_EmissionsInputEntriesEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EmissionsInputs {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmissionsInputs();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = EmissionsInputs_EmissionsInputEntriesEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.emissionsInputEntries[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EmissionsInputs {
    return {
      emissionsInputEntries: isObject(object.emissionsInputEntries)
        ? Object.entries(object.emissionsInputEntries).reduce<{ [key: string]: EmissionsInputs_EmissionsInputEntry }>(
          (acc, [key, value]) => {
            acc[key] = EmissionsInputs_EmissionsInputEntry.fromJSON(value);
            return acc;
          },
          {},
        )
        : {},
    };
  },

  toJSON(message: EmissionsInputs): unknown {
    const obj: any = {};
    if (message.emissionsInputEntries) {
      const entries = Object.entries(message.emissionsInputEntries);
      if (entries.length > 0) {
        obj.emissionsInputEntries = {};
        entries.forEach(([k, v]) => {
          obj.emissionsInputEntries[k] = EmissionsInputs_EmissionsInputEntry.toJSON(v);
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EmissionsInputs>, I>>(base?: I): EmissionsInputs {
    return EmissionsInputs.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EmissionsInputs>, I>>(object: I): EmissionsInputs {
    const message = createBaseEmissionsInputs();
    message.emissionsInputEntries = Object.entries(object.emissionsInputEntries ?? {}).reduce<
      { [key: string]: EmissionsInputs_EmissionsInputEntry }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = EmissionsInputs_EmissionsInputEntry.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseEmissionsInputs_EmissionsInputEntry(): EmissionsInputs_EmissionsInputEntry {
  return { dataSource: undefined, dataStrategy: undefined };
}

export const EmissionsInputs_EmissionsInputEntry = {
  encode(message: EmissionsInputs_EmissionsInputEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.dataSource !== undefined) {
      writer.uint32(10).string(message.dataSource);
    }
    if (message.dataStrategy !== undefined) {
      writer.uint32(18).string(message.dataStrategy);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EmissionsInputs_EmissionsInputEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmissionsInputs_EmissionsInputEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.dataSource = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.dataStrategy = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EmissionsInputs_EmissionsInputEntry {
    return {
      dataSource: isSet(object.dataSource) ? globalThis.String(object.dataSource) : undefined,
      dataStrategy: isSet(object.dataStrategy) ? globalThis.String(object.dataStrategy) : undefined,
    };
  },

  toJSON(message: EmissionsInputs_EmissionsInputEntry): unknown {
    const obj: any = {};
    if (message.dataSource !== undefined) {
      obj.dataSource = message.dataSource;
    }
    if (message.dataStrategy !== undefined) {
      obj.dataStrategy = message.dataStrategy;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EmissionsInputs_EmissionsInputEntry>, I>>(
    base?: I,
  ): EmissionsInputs_EmissionsInputEntry {
    return EmissionsInputs_EmissionsInputEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EmissionsInputs_EmissionsInputEntry>, I>>(
    object: I,
  ): EmissionsInputs_EmissionsInputEntry {
    const message = createBaseEmissionsInputs_EmissionsInputEntry();
    message.dataSource = object.dataSource ?? undefined;
    message.dataStrategy = object.dataStrategy ?? undefined;
    return message;
  },
};

function createBaseEmissionsInputs_EmissionsInputEntriesEntry(): EmissionsInputs_EmissionsInputEntriesEntry {
  return { key: "", value: undefined };
}

export const EmissionsInputs_EmissionsInputEntriesEntry = {
  encode(message: EmissionsInputs_EmissionsInputEntriesEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      EmissionsInputs_EmissionsInputEntry.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EmissionsInputs_EmissionsInputEntriesEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmissionsInputs_EmissionsInputEntriesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = EmissionsInputs_EmissionsInputEntry.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EmissionsInputs_EmissionsInputEntriesEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? EmissionsInputs_EmissionsInputEntry.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: EmissionsInputs_EmissionsInputEntriesEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = EmissionsInputs_EmissionsInputEntry.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EmissionsInputs_EmissionsInputEntriesEntry>, I>>(
    base?: I,
  ): EmissionsInputs_EmissionsInputEntriesEntry {
    return EmissionsInputs_EmissionsInputEntriesEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EmissionsInputs_EmissionsInputEntriesEntry>, I>>(
    object: I,
  ): EmissionsInputs_EmissionsInputEntriesEntry {
    const message = createBaseEmissionsInputs_EmissionsInputEntriesEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? EmissionsInputs_EmissionsInputEntry.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseEmissionsBreakdown(): EmissionsBreakdown {
  return { wttEmissionsGramsPerPax: undefined, ttwEmissionsGramsPerPax: undefined };
}

export const EmissionsBreakdown = {
  encode(message: EmissionsBreakdown, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.wttEmissionsGramsPerPax !== undefined) {
      EmissionsGramsPerPax.encode(message.wttEmissionsGramsPerPax, writer.uint32(10).fork()).ldelim();
    }
    if (message.ttwEmissionsGramsPerPax !== undefined) {
      EmissionsGramsPerPax.encode(message.ttwEmissionsGramsPerPax, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EmissionsBreakdown {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmissionsBreakdown();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.wttEmissionsGramsPerPax = EmissionsGramsPerPax.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ttwEmissionsGramsPerPax = EmissionsGramsPerPax.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EmissionsBreakdown {
    return {
      wttEmissionsGramsPerPax: isSet(object.wttEmissionsGramsPerPax)
        ? EmissionsGramsPerPax.fromJSON(object.wttEmissionsGramsPerPax)
        : undefined,
      ttwEmissionsGramsPerPax: isSet(object.ttwEmissionsGramsPerPax)
        ? EmissionsGramsPerPax.fromJSON(object.ttwEmissionsGramsPerPax)
        : undefined,
    };
  },

  toJSON(message: EmissionsBreakdown): unknown {
    const obj: any = {};
    if (message.wttEmissionsGramsPerPax !== undefined) {
      obj.wttEmissionsGramsPerPax = EmissionsGramsPerPax.toJSON(message.wttEmissionsGramsPerPax);
    }
    if (message.ttwEmissionsGramsPerPax !== undefined) {
      obj.ttwEmissionsGramsPerPax = EmissionsGramsPerPax.toJSON(message.ttwEmissionsGramsPerPax);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EmissionsBreakdown>, I>>(base?: I): EmissionsBreakdown {
    return EmissionsBreakdown.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EmissionsBreakdown>, I>>(object: I): EmissionsBreakdown {
    const message = createBaseEmissionsBreakdown();
    message.wttEmissionsGramsPerPax =
      (object.wttEmissionsGramsPerPax !== undefined && object.wttEmissionsGramsPerPax !== null)
        ? EmissionsGramsPerPax.fromPartial(object.wttEmissionsGramsPerPax)
        : undefined;
    message.ttwEmissionsGramsPerPax =
      (object.ttwEmissionsGramsPerPax !== undefined && object.ttwEmissionsGramsPerPax !== null)
        ? EmissionsGramsPerPax.fromPartial(object.ttwEmissionsGramsPerPax)
        : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
